<nb-layout>
  <nb-layout-header>LeShack</nb-layout-header>

  <nb-sidebar containerFixed="false">
    <app-leshack-menu></app-leshack-menu>
  </nb-sidebar>

  <nb-layout-column>
    <router-outlet></router-outlet>
  </nb-layout-column>
</nb-layout>
