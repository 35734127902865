import { Component, OnInit } from '@angular/core';
import { NbAuthService, NbTokenService } from '@nebular/auth';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit {
  public authorization: string;
  public constructor(private authService: NbAuthService, private tokenService: NbTokenService) {}

  public ngOnInit(): void {
    this.authorization = localStorage.getItem('AUTH_KEY');
  }

  public logout = (): void => {
    this.tokenService.clear();
    this.authService.logout('email');
  };
}
